<template>
  <v-container
    id="login"
    class="fill-height justify-center"
    tag="section"
  >
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">
                Nouveau mot de passe
              </h1>
            </div>
          </template>

          <v-card-text class="text-center">
            <div class="text-center grey--text body-1 font-weight-light">
              Réinitialisez votre mot de passe
            </div>

            <div
              v-if="completed"
              class="mb-8 mt-10"
            >
              <p>
                {{ message }}
              </p>
              <v-btn
                color="success"
                outlined
                class="mt-4"
                @click="completed=false"
              >
                Retour
              </v-btn>
            </div>
            <v-form
              v-else
              ref="réinitialisationMotDePasseForm"
              aria-autocomplete="off"
            >
              <v-text-field
                v-model="motPasse"
                type="password"
                class="mb-8 mt-10"
                color="secondary"
                label="Nouveau mot de passe"
                prepend-icon="mdi-lock-outline"
                data-cy="tfNouveauMotPasse"
                autocomplete="new-password"
                name="newPassword"
                :rules="[v => !!v || 'Le champ est requis']"
              />

              <v-text-field
                v-model="confirmationMotPasse"
                autocomplete="new-password"
                name="newPasswordConfirmation"
                type="password"
                class="mb-8"
                color="secondary"
                label="Confirmez votre mot de passe"
                prepend-icon="mdi-lock-outline"
                data-cy="tfConfirmationNouveauMotPasse"
                :rules="[
                  v => !!v || 'Le champ est requis',
                  v => (motPasse === confirmationMotPasse) || 'Les mots de passe ne correspondent pas',
                ]"
              />

              <pages-btn
                large
                color=""
                depressed
                class="v-btn--text success--text"
                @click="onRéinitialiserMotDePasse()"
              >
                Modifier
              </pages-btn>
            </v-form>
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
  import restApiService from '@/services/restApiService.js'

  export default {
    name: 'ReinitialiserMotDePasse',

    components: {
      PagesBtn: () => import('../pages/components/Btn'),
    },

    data: () => ({
      motPasse: '',
      confirmationMotPasse: '',
      cle: '',
      completed: false,
      message: '',
    }),
    mounted () {
      this.cle = this.$route.query.cle
    },
    methods: {
      onRéinitialiserMotDePasse () {
        const valid = this.$refs.réinitialisationMotDePasseForm.validate()
        if (!valid) {
          return
        }

        const KeyAndPassword = {
          key: this.cle,
          newPassword: this.confirmationMotPasse,
        }
        restApiService.post('/api/account/reset-password/finish', KeyAndPassword).then((result) => {
          this.message = 'Félicitation ! Votre mot de passe a été modifié avec succès'
          this.completed = true
          this.$refs.réinitialisationMotDePasseForm.reset()
        }).catch((error) => {
          console.log(error)
          this.message = 'Une erreur est survenue lors de la réinitialisation de votre mot de passe'
          this.completed = true
          this.$refs.réinitialisationMotDePasseForm.reset()
        })
      },
    },
  }
</script>
